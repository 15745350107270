import React, {useCallback, useEffect, useRef, useState} from 'react';
import {IndeterminateProgressBar} from 'app/components/sharedReactComponents/IndeterminateProgressBar';
import {RepeatedCall} from 'app/util/RepeatedCall';
import {stringComparator} from 'app/util/Sort';
import {uniqueNewName} from 'app/util/newName';
import {NewStreamingDestinationTypeSelector} from 'app/components/Settings/StreamingDestinations/NewStreamingDestinationTypeSelector';
import {NEW_STREAMING_DESTINATION_TYPE_SELECTOR_VARIANT} from 'app/components/Settings/StreamingDestinations/NewStreamingDestinationTypeSelector/constants';
import {StreamingDestinationsPanel} from 'app/components/Settings/StreamingDestinations/StreamingDestinationsPanel';
import {minutesToMilliseconds} from 'app/util/timeConverter';
import {isNil} from 'app/util/isNil';
import {streamApiService} from 'app/services/api/stream/StreamApiService';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';
import {streamingServicesRepository} from 'app/components/StreamingServices';
import {useCloud} from 'app/components/features/cloud';

const STREAMING_REPEAT_CALL_INITIAL_TIMEOUT = minutesToMilliseconds(2);
const STREAMING_REPEAT_CALL_MAX_TIMEOUT = minutesToMilliseconds(5);

export function StreamingDestinations() {
  const {user} = useCloud();
  const userRole = user.role;

  const streamingRepeatedCall = useRef<RepeatedCall | undefined>();

  const [initialFetchFinished, setInitialFetchFinished] = useState(false);
  const [streamingDestinations, setStreamingDestinations] = useState<
    AnyStreamingDestinationModelType[]
  >([]);

  useEffect(() => {
    streamingRepeatedCall.current = new RepeatedCall({
      call: async () => {
        try {
          const response = await streamApiService.getStreams();
          const streamingDestinationModels = response
            .map((d) => streamingServicesRepository.createStreamingDestinationModel(d))
            .filter((model): model is AnyStreamingDestinationModelType => !isNil(model))
            .sort((a, b) => stringComparator(a.getName(), b.getName()));

          setStreamingDestinations(streamingDestinationModels);

          return streamingDestinationModels;
        } catch {
          setStreamingDestinations([]);

          return [];
        } finally {
          setInitialFetchFinished(true);
        }
      },
      initialTimeoutMs: STREAMING_REPEAT_CALL_INITIAL_TIMEOUT,
      maxTimeoutMs: STREAMING_REPEAT_CALL_MAX_TIMEOUT,
    });

    streamingRepeatedCall.current.start(true);

    return () => {
      streamingRepeatedCall.current?.stop();
    };
  }, []);

  const createServiceAction = useCallback((serviceType) => {
    const service = streamingServicesRepository.getServiceByType(serviceType);

    if (!service) {
      return;
    }

    const newName = uniqueNewName(
      service.getName() ?? '',
      streamingDestinations.map((a) => a.getName()),
    );
    let createdName: string | undefined;

    return service
      .createService(newName)
      .then((name) => (createdName = name))
      .then(async () => streamingRepeatedCall.current?.restart(true))
      .then((streamingDestinationModels) => {
        return streamingDestinationModels.find(
          (streamingDestination) => streamingDestination.getName() === createdName,
        );
      });
  }, []);

  const handleStreamingDestinationDelete = useCallback((streamingDestination) => {
    const deleteId = streamingDestination.getId();

    setStreamingDestinations((prevStreamingDestinations) => {
      return prevStreamingDestinations.filter((item) => item.getId() !== deleteId);
    });

    streamingRepeatedCall.current?.restart(true);
  }, []);

  if (!initialFetchFinished) {
    return <IndeterminateProgressBar />;
  }

  const hasStreamDestinations = streamingDestinations.length > 0;
  const userCanEditStreamingDestinations = userRole.canEditStreamingDestinations();

  if (!hasStreamDestinations && !userCanEditStreamingDestinations) {
    return (
      <div className="streaming-destinations__warning">There are no streaming destinations.</div>
    );
  }

  return (
    <div className="streaming-destinations">
      <div className="container streaming-destinations__container">
        <div className="streaming-destinations__content">
          {hasStreamDestinations ? (
            <StreamingDestinationsPanel
              streamingDestinations={streamingDestinations}
              editable={userCanEditStreamingDestinations}
              onDelete={handleStreamingDestinationDelete}
            />
          ) : (
            <>
              {userCanEditStreamingDestinations && (
                <NewStreamingDestinationTypeSelector
                  variant={NEW_STREAMING_DESTINATION_TYPE_SELECTOR_VARIANT.TILE}
                  createServiceAction={createServiceAction}
                />
              )}
            </>
          )}
        </div>

        {hasStreamDestinations && userCanEditStreamingDestinations && (
          <div className="streaming-destinations__aside">
            <NewStreamingDestinationTypeSelector createServiceAction={createServiceAction} />
          </div>
        )}
      </div>
    </div>
  );
}
