import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {Stack, Typography} from '@mui/material';
import {ChangeEvent, ClassName} from 'app/types/common';
import {SrtSettingsCallback} from 'app/components/pages/MeetingDetails/StreamSettings/types';
import {Tabs, TABS_THEME} from 'app/components/sharedReactComponents/Tabs/Tabs';
import {Tab} from 'app/components/sharedReactComponents/Tabs/Tab';
import {TabPanels} from 'app/components/sharedReactComponents/Tabs/TabPanels';
import {TabPanel} from 'app/components/sharedReactComponents/Tabs/TabPanel';
import {
  CustomConfig,
  CustomSrtConnection,
} from 'app/components/pages/MeetingDetails/StreamSettings/StreamConnectionSettings/CustomSrtConnection/CustomSrtConnection';
import {CloudSrtConnection} from 'app/components/pages/MeetingDetails/StreamSettings/StreamConnectionSettings/CloudSrtConnection/CloudSrtConnection';
import {useSrtSettings} from 'app/components/pages/MeetingDetails/StreamSettings/SrtSettingsFormContext';
import {isParticipantStreamSettings} from 'app/types/guards/connect';
import {SrtApiService} from 'app/services/api/meetings/SrtApiService';
import {LoadingPlaceholder} from 'app/components/sharedReactComponents/LoadingPlaceholder';
import {ConnectMapper} from 'app/util/mappers/ConnectMapper/ConnectMapper';
import {stringComparator} from 'app/util/Sort';
import {isNil} from 'app/util/isNil';
import {palette} from 'app/themes/app';
import {useCloud} from 'app/components/features/cloud';

type ConnectionType = 'cloud' | 'custom';

function CloudSyncLoader() {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
      <LoadingPlaceholder /> <span>Loading cloud devices</span>
    </Stack>
  );
}

interface Props extends ClassName, SrtSettingsCallback {
  range: App.Connect.PortRange;
  dns: string;
}

export function StreamConnectionSettings({className, dns, range, onSync}: Props) {
  const {team} = useCloud();
  const {form} = useSrtSettings();
  const [type, setType] = useState<ConnectionType>(() => getConnectionType(form.settings));

  const supportUnify = team.capabilities.has('unify');

  const [isLoadingCloud, setIsLoadingCloud] = useState(type === 'cloud');
  const [isLoadingSources, setIsLoadingSources] = useState(false);

  const [entities, setEntities] = useState<App.Connect.CloudEntity[]>([]);
  const [sources, setSources] = useState<App.Connect.EntitySource[]>([]);

  const handleTabChange = async ({target: {value}}: ChangeEvent<ConnectionType>) => {
    setType(value);
    const isCloud = value === 'cloud';
    form.setSettings({integration: isCloud});

    if (!isCloud) {
      await onSync();
      return;
    }

    form.setSettings({key: '', size: 0});
  };

  const handleSetCustomConfig = (config: Partial<CustomConfig>) => {
    form.setSettings(config);
  };

  const handleSetCloudConfig = (config: Partial<App.Connect.CloudConnection>) => {
    if (isParticipantStreamSettings(form.settings)) {
      const current = form.settings.cloud;
      form.setSettings({cloud: {...current, ...config}});
    }
  };

  const handleEntityChange = async (entity: App.Connect.CloudEntity | null) => {
    if (!isNil(entity)) {
      try {
        setIsLoadingSources(true);
        const inputs = await SrtApiService.getEntitySources(entity.id);

        setSources(inputs);
      } catch {
        setSources([]);
      } finally {
        setIsLoadingSources(false);
      }
    } else {
      setSources([]);
    }

    await onSync();
  };

  const {mode, url, port} = form.settings;
  const customConfig = {mode, url, port};

  useEffect(() => {
    const syncCloudSettings = async () => {
      if (!isParticipantStreamSettings(form.settings)) {
        return;
      }

      const {cloud} = form.settings;
      setIsLoadingCloud(true);

      const items = await getOnlineEntities();
      setEntities(items.map(ConnectMapper.mapCloudEntity));

      const {deviceId} = cloud;
      const entity = items.find((i) => i.id === deviceId);

      if (!entity) {
        form.setSettings({
          cloud: {deviceId: '', deviceName: '', sourceId: '', sourceName: '', type: 'device'},
        });
        setSources([]);
      } else {
        const srtEntity: App.Connect.SrtEntity = {
          deviceId: entity.id,
          deviceName: entity.name,
          type: entity.type,
        };
        form.setSettings({cloud: {...cloud, ...srtEntity}});

        const inputs = await getEntitySource(deviceId);
        setSources(inputs);

        const {sourceId} = form.settings.cloud;
        const source = inputs.find((i) => i.id === sourceId && !i.active);

        const srtSource: App.Connect.SrtSource = {
          sourceId: source?.id ?? '',
          sourceName: source?.name ?? '',
        };

        form.setSettings({cloud: {...cloud, ...srtSource}});
      }

      setIsLoadingCloud(false);
      await onSync();
    };

    if (type === 'cloud') {
      void syncCloudSettings();
    }
  }, [type, form, onSync]);

  if (!isParticipantStreamSettings(form.settings)) {
    return (
      <div className={classNames('stream-connection-settings', className)}>
        <CustomSrtConnection
          className="stream-connection-settings__feed-panel"
          config={customConfig}
          range={range}
          errors={form.errors}
          dns={dns}
          setConfig={handleSetCustomConfig}
          onSync={onSync}
        />
      </div>
    );
  }

  return (
    <div className={classNames('stream-connection-settings', className)}>
      <Typography color={palette.darkerGray} mb={3}>
        Configure where Connect will send the participant's AV signals.
      </Typography>

      <Tabs
        className="stream-connection-settings__tabs"
        theme={TABS_THEME.SETTINGS}
        value={type}
        onChange={handleTabChange}
      >
        <Tab data-id="cloud_tab" label="Epiphan" value="cloud" />
        <Tab data-id="custom_tab" label="Manual SRT" value="custom" />
      </Tabs>

      <TabPanels value={type}>
        <TabPanel className="stream-connection-settings__panel" value="cloud">
          {isLoadingCloud ? (
            <CloudSyncLoader />
          ) : (
            <CloudSrtConnection
              entities={entities}
              sources={sources}
              errors={form.errors}
              isLoadingSources={isLoadingSources}
              config={form.settings.cloud}
              supportUnify={supportUnify}
              setConfig={handleSetCloudConfig}
              onEntityChange={handleEntityChange}
              onSync={onSync}
            />
          )}
        </TabPanel>

        <TabPanel className="stream-connection-settings__panel" value="custom">
          <CustomSrtConnection
            config={customConfig}
            errors={form.errors}
            range={range}
            dns={dns}
            setConfig={handleSetCustomConfig}
            onSync={onSync}
          />
        </TabPanel>
      </TabPanels>
    </div>
  );
}

function sortEntities(a: App.Connect.CloudEntity, b: App.Connect.CloudEntity) {
  if (a.type === b.type) {
    return stringComparator(a.name, b.name);
  }

  if (a.type === 'device') {
    return 1;
  }

  return -1;
}

async function getOnlineEntities(): Promise<App.Connect.CloudEntity[]> {
  try {
    const result = await SrtApiService.getOnlineCloudEntities();
    return result.map(ConnectMapper.mapCloudEntity).sort(sortEntities);
  } catch {
    return [];
  }
}

async function getEntitySource(id: string): Promise<App.Connect.EntitySource[]> {
  try {
    const sources = await SrtApiService.getEntitySources(id);
    return sources;
  } catch {
    return [];
  }
}

function getConnectionType(settings: App.Connect.StreamSettings): ConnectionType {
  if (isParticipantStreamSettings(settings)) {
    return settings.integration ? 'cloud' : 'custom';
  }

  return 'cloud';
}
