import {
  autocompleteClasses,
  createTheme,
  inputBaseClasses,
  outlinedInputClasses,
} from '@mui/material';
import {scrollbarSx} from 'app/themes/styles';
import {packSx} from 'app/util/packSx/packSx';
import {blue, grey, lightGreen, red} from '@mui/material/colors';

export const text = {
  // TODO: replace from palette
  group: '#7e8691',
  label: '#848489',
  disabled: 'rgba(51, 51, 51, 0.75)',
};

export const borders = {
  normal: '#c0c0c2',
  hover: '#7f838c',
  focus: '#333c50',
  error: '#ff0048',
};

export const backgrounds = {
  disabled: 'rgba(51, 51, 51, 0.09)',
  blue: '#eff6ff',
  gray: '#f6f7f8',
  lightGray: '#f3f4f6',
  gray1: '#fafafa',
};

export const palette = {
  black: '#03030c',
  gray: '#73839c',
  grayMedium: '#dbdbdb',
  darkerGray: '#7e8691',
  gray1: '#4a4a4a',
  gray2: '#4f4f4f',
  gray3: '#828282',
  gray4: '#bdbdbd',
  gray5: '#5e6871',
  gray6: '#979797',
  gray7: '#a1a7af',
  gray9: '#848fa8',
  blue: '#4a90e2',
  red: '#ff0048',
  green: '#8cbe3f',
  green2: '#6b9936',
  badge: '#333c50',
  orange: '#ffc800',
  orange2: '#ef7a12',
  orange3: '#fbab00',
  obscure: '#667ba8',
  white: '#fff',
  dark: '#2d3546',
  dark1: '#222836',
};

const lightTheme = createTheme({
  spacing: 8,
  shape: {borderRadius: 4},
  palette: {
    primary: {main: palette.blue},
    secondary: {main: lightGreen[700]},
    error: {main: red.A400},
    info: {main: grey[600]},
    text: {disabled: grey[500]},
  },
  typography: {
    fontSize: 14,
    htmlFontSize: 16,
    fontFamily: 'Open Sans, sans-serif',
    button: {textTransform: 'none'},
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: (t) =>
        t.unstable_sx(
          packSx(scrollbarSx, {
            html: {fontSize: 14},
            body: {overscrollBehaviorY: 'none'},
          }),
        ),
    },
    MuiDialog: {defaultProps: {scroll: 'body'}},
    MuiSelect: {
      defaultProps: {variant: 'outlined'},
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {borderColor: borders.hover},
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: borders.focus,
              borderWidth: 1,
              boxShadow: '0 0 0 2px rgba(185, 185, 185, 0.4)',
            },
            [`&.Mui-disabled .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: borders.normal,
              backgroundColor: backgrounds.disabled,
              color: text.disabled,
            },
            [`&.Mui-error .${outlinedInputClasses.notchedOutline}`]: {borderColor: borders.error},
          }),
        input: ({theme}) =>
          theme.unstable_sx({
            padding: '8px 7px',
            '&::placeholder': {color: text.label, opacity: 0.5},
          }),
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            border: '1px solid',
            borderRadius: `${theme.shape.borderRadius}px`,
            borderColor: borders.normal,
            bgcolor: 'white',
            [`&.Mui-focused`]: {
              boxShadow: '0 0 0 2px rgba(185, 185, 185, 0.4)',
              borderColor: borders.focus,
            },
            [`&.Mui-disabled`]: {backgroundColor: backgrounds.disabled},
            [`&.Mui-error`]: {borderColor: borders.error},
            [`&.${inputBaseClasses.adornedEnd}`]: {pr: 1},
          }),
        input: ({theme}) =>
          theme.unstable_sx({
            padding: '8px 7px',
            borderRadius: `${theme.shape.borderRadius}px`,

            '&::placeholder': {color: text.label, opacity: 1},
          }),
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            color: text.label,
            fontSize: '12px',
            transform: 'none',

            '&.Mui-focused': {color: text.label},
            '&.Mui-error': {color: text.label},
            '&.Mui-disabled': {color: text.label},
          }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {margin: '0px', fontSize: '12px'},
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            [`& .${outlinedInputClasses.root}`]: {
              padding: '8px 7px',

              [`& .${autocompleteClasses.input}`]: {py: 0, pl: 0},
            },
          }),
      },
    },
    MuiDialogTitle: {
      defaultProps: {variant: 'h5', textAlign: 'center'},
      styleOverrides: {
        root: ({theme}) => theme.unstable_sx({pt: 3, px: 5}),
      },
    },
    MuiDialogContent: {
      styleOverrides: {root: ({theme}) => theme.unstable_sx({px: 5, py: 1})},
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({theme}) => theme.unstable_sx({justifyContent: 'center', px: 5, pb: 3}),
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: 'top',
        disableInteractive: true,
      },
      styleOverrides: {
        tooltip: {fontSize: 12},
      },
    },
  },
});

const darkTheme = createTheme({
  palette: {mode: 'dark', primary: {main: blue[500]}, info: {main: grey[50]}},
  components: {
    MuiTooltip: {
      styleOverrides: {tooltip: {fontSize: 12}},
    },
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    button: {textTransform: 'none'},
  },
});

export {lightTheme, darkTheme};
