import React from 'react';
import {Stack, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {EdgeAfuSwitcher, useUnitFiles} from 'app/components/features/files';
import {useUnitAfu} from 'app/components/entities/afu';
import {FilesLibrary} from 'app/components/widgets/files';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';

const devices = new Map<string, AnyDeviceModelType>();

type Props = Sx & {
  teamId: string;
  userId: string;
  userName: string;
  deviceId: string;
  online: boolean;
};

export function UnitFiles({teamId, userId, userName, sx, deviceId, online}: Props) {
  const {data: recordings, isSuccess} = useUnitFiles({enabled: true, deviceId, teamId});
  const {data: afu} = useUnitAfu({deviceId, enabled: online, refetchInterval: 1000 * 60});

  return (
    <Stack sx={sx}>
      <Typography mb={1} fontWeight={600} variant="h5">
        Recordings
      </Typography>

      {afu && <EdgeAfuSwitcher sx={{mb: 1}} deviceId={deviceId} afu={afu} />}

      {isSuccess ? (
        <FilesLibrary
          teamId={teamId}
          recordings={recordings}
          devices={devices}
          userId={userId}
          userName={userName}
          displayDevice={false}
        />
      ) : (
        <Typography mt={5} textAlign="center" variant="h6" color="text.secondary">
          Loading device recordings
        </Typography>
      )}
    </Stack>
  );
}
