import React, {useCallback} from 'react';
import classNames from 'classnames';
import {ListSearchFilter} from 'app/components/Dashboard/ListSearchFilter';
import {Buttons} from 'app/components/Dashboard/DashboardListHeader/Buttons';
import {Callback, ClassName} from 'app/types/common';

interface Props extends ClassName {
  filterValue: string;
  buttonsComponent: React.ReactNode;
  onFilter: Callback;
  showSearch: boolean;
}

export function DashboardListHeader({
  className,
  filterValue,
  buttonsComponent,
  showSearch,
  onFilter,
}: Props) {
  const handleFilterChange = useCallback(
    ({target: {value}}) => {
      onFilter(value);
    },
    [onFilter],
  );

  const handleFilterClear = useCallback(() => {
    onFilter('');
  }, [onFilter]);

  return (
    <div className={classNames('dashboard-list-header', className)}>
      <div className="dashboard-list-header__container">
        <div className="dashboard-list-header__content container">
          <div className="dashboard-list-header__filter">
            {showSearch && (
              <ListSearchFilter
                value={filterValue}
                onChange={handleFilterChange}
                onClear={handleFilterClear}
              />
            )}
          </div>

          <Buttons buttonsComponent={buttonsComponent} />
        </div>
      </div>
    </div>
  );
}
