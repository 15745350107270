import React from 'react';
import {Divider, List, ListItemButton, ListItemText, Stack} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import {DataId, Sx} from 'app/types/common';
import {palette} from 'app/themes/app';
import {router} from 'app/router/main';
import {Team} from 'app/components/entities/team';

interface ListProps extends Sx, DataId {
  teamId: string;
  teams: Team.Team[];
  onMyTeam: () => void;
  onCreate: () => Promise<void>;
  onLogout: () => Promise<void>;
}

export const TeamList = React.forwardRef<any, ListProps>(
  ({sx, teamId, teams, onCreate, onMyTeam, onLogout}, ref) => {
    const handleSelect = (id: string) => {
      if (teamId !== id) {
        router.goToTeam(id);
      }
    };

    return (
      <Stack ref={ref} sx={sx}>
        <List sx={{overflowY: 'auto'}}>
          {teams.map((t) => (
            <ListItemButton data-id={`team-${t.id}`} key={t.id} onClick={() => handleSelect(t.id)}>
              <ListItemText primaryTypographyProps={{noWrap: true, sx: {lineClamp: 2}}}>
                {t.name}
              </ListItemText>

              {teamId === t.id && <CheckIcon sx={{color: palette.blue}} />}
            </ListItemButton>
          ))}
        </List>

        <Divider />

        <List>
          <ListItemButton data-id="add-new-team" onClick={onCreate}>
            <ListItemText primaryTypographyProps={{color: palette.blue}}>
              + Add new team
            </ListItemText>
          </ListItemButton>
          <ListItemButton data-id="my-team" onClick={onMyTeam}>
            My team
          </ListItemButton>
          <ListItemButton data-id="logout" onClick={onLogout}>
            Log out
          </ListItemButton>
        </List>
      </Stack>
    );
  },
);
