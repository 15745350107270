import {Router} from 'app/router/Router';

const routes = {
  dashboard: {
    url: 'dashboard',
    title: 'Dashboard',
  },
  devices: {
    url: 'devices',
    title: 'All devices',
  },
  devicesAlerts: {
    url: 'devices/alerts',
    title: 'Alerts',
  },
  deviceDetails: {
    url: 'devices/:deviceId/details',
    title: 'Device info',
  },
  presets: {
    url: 'presets',
    title: 'Configuration presets',
  },
  welcome: {
    url: 'welcome',
    title: 'Pair device',
  },
  oneClickDevicePairing: {
    url: 'p',
    title: 'Pair device',
  },
  agreement: {
    url: 'agreement',
    title: 'Subscription Agreement',
  },
  acceptanceUsePolicy: {
    url: 'acceptance-use-policy',
    title: 'Epiphan Acceptable Use Policy',
  },

  team: {
    url: 'team',
    title: 'Team',
  },
  settings: {
    url: 'settings',
    title: 'Settings',
  },
  alerts: {
    url: 'settings/alerts',
    title: 'Manage alerts',
  },
  fileTransfer: {
    url: 'settings/file-transfer',
    title: 'File transfer locations',
  },
  streamingDestinations: {
    url: 'settings/streaming-destinations',
    title: 'Streaming destinations',
  },
  apiTokens: {
    url: 'settings/api-tokens',
    title: 'API tokens',
  },
  accountSettings: {
    url: 'settings/account',
    title: 'Account settings',
  },
  settingBillingUsage: {
    url: 'settings/billing-usage',
    title: 'Billing and Usage',
  },
  connectAccountSettings: {
    url: 'settings/connect',
    title: 'Epiphan Connect',
  },
  transcribeDevices: {
    url: 'livescrypt',
    title: 'LiveScrypt',
  },
  transcribeDeviceDetails: {
    url: 'livescrypt/:deviceId/details',
    title: 'LiveScrypt device details',
  },
  events: {
    url: 'events',
    title: 'Events',
  },
  projects: {
    url: 'unify/projects',
    title: 'Unify projects',
  },
  projectDetails: {
    url: 'unify/projects/:deviceId/details',
    title: 'Unify project details',
  },
  meetings: {
    url: 'connect/meetings',
    title: 'Meetings',
  },
  meetingDetails: {
    url: 'connect/meetings/:meetingId/details',
    title: 'Meeting Details',
  },
  recordings: {
    url: 'recordings',
    title: 'Recordings',
  },
} as const;

export type MainRoutes = keyof typeof routes;

const router = new Router<MainRoutes>({
  routes,
  defaultRoute: {
    name: 'devices',
    params: {},
  },
});

export {router};
