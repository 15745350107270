import React, {useMemo} from 'react';
import {filesize} from 'filesize';
import {Typography} from '@mui/material';
import {DataId, Sx} from 'app/types/common';
import {Files} from 'app/components/entities/files';
import {pluralizeN} from 'app/components/helpers/commonHelpers';

interface Props extends Sx, DataId {
  recordings: Files.Recording[];
}

export function TotalAndSize({dataId, sx, recordings}: Props) {
  const size = useMemo(() => recordings.reduce((acc, file) => acc + file.size, 0), [recordings]);

  return (
    <Typography data-id={dataId} sx={sx}>
      {recordings.length} {pluralizeN('file', recordings.length)} | {filesize(size)}
    </Typography>
  );
}
