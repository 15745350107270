import React, {useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {Box, Button, Dialog, DialogActions, DialogTitle, Typography} from '@mui/material';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {Prompt} from 'app/types/common';
import {useMounted} from 'app/hooks/useIsMounted';
import {NamesList} from 'app/components/sharedReactComponents/ItemsEnumeration';

type Props = {
  recordings: string[];
  loading: boolean;
  batching: boolean;
  onDownload: () => Promise<void>;
  onCancel: () => void;
};

function DownloadRecordings({recordings, batching, loading, onDownload, onCancel}: Props) {
  return (
    <Box>
      <DialogTitle>
        Download <NamesList variant="inherit" color="primary.main" names={recordings} max={3} />{' '}
        {pluralizeN('recording', recordings.length)}
      </DialogTitle>

      {batching && (
        <Typography mb={2} textAlign="center" color="warning.main">
          Do not close the tab until download is finished
        </Typography>
      )}

      <DialogActions>
        <LoadingButton
          data-id="confirm-delete-btn"
          loading={loading}
          color="secondary"
          variant="contained"
          onClick={onDownload}
        >
          Download
        </LoadingButton>

        <Button data-id="cancel-delete-btn" variant="outlined" color="info" onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Box>
  );
}

type DialogProps = Prompt & Omit<Props, 'onCancel' | 'loading'>;

export function DownloadRecordingsDialog({
  open,
  recordings,
  batching,
  onDownload,
  onClose,
}: DialogProps) {
  const mounted = useMounted();
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    try {
      setLoading(true);
      await onDownload();
    } finally {
      if (mounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" onClose={onClose}>
      <DownloadRecordings
        loading={loading}
        recordings={recordings}
        batching={batching}
        onDownload={handleDownload}
        onCancel={onClose}
      />
    </Dialog>
  );
}
