import React, {useState} from 'react';
import dayjs, {Dayjs} from 'dayjs';
import {Button, Dialog, DialogActions, DialogTitle, Divider, Stack, alpha} from '@mui/material';
import {DateCalendar, pickersMonthClasses, pickersYearClasses} from '@mui/x-date-pickers';
import {Prompt, StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';

const calendarSx: StyleSx = {
  [`& .${pickersYearClasses.yearButton}, & .${pickersMonthClasses.monthButton}`]: {
    display: 'block',
  },
};
const daysSx: StyleSx = (t) => ({bgcolor: alpha(t.palette.primary.main, 0.15)});
const actionSx: StyleSx = {minWidth: 80};

interface PickerProps extends Sx {
  defaultStart?: number;
  defaultEnd?: number;
  onSelect: (period: [number, number]) => void;
  onCancel: () => void;
}

function DatesPicker({defaultStart, defaultEnd, onSelect, onCancel}: PickerProps) {
  const [start, setStart] = useState<Dayjs | null>(defaultStart ? dayjs.unix(defaultStart) : null);
  const [end, setEnd] = useState<Dayjs | null>(defaultEnd ? dayjs.unix(defaultEnd) : null);

  const handleSave = () => {
    if (start && end) {
      onSelect([start.unix(), end.endOf('day').unix()]);
    }
  };

  const bothSet = start && end;

  return (
    <>
      <DialogTitle>Select dates range</DialogTitle>

      <Stack direction="row" divider={<Divider orientation="vertical" flexItem={true} />}>
        <DateCalendar
          data-id="start-calendar"
          sx={calendarSx}
          value={start}
          maxDate={end}
          views={['year', 'month', 'day']}
          slotProps={{
            day: (state) => ({
              sx: packSx(bothSet && state.day && inRange(state.day, start, end) && daysSx),
            }),
          }}
          onChange={(value) => setStart(value)}
        />

        <DateCalendar
          data-id="end-calendar"
          sx={calendarSx}
          views={['year', 'month', 'day']}
          value={end}
          minDate={start}
          slotProps={{
            day: (state) => ({
              sx: packSx(bothSet && state.day && inRange(state.day, start, end) && daysSx),
            }),
          }}
          onChange={(value) => setEnd(value)}
        />
      </Stack>

      <DialogActions>
        <Button
          data-id="apply-button"
          sx={actionSx}
          disabled={!bothSet}
          variant="outlined"
          color="primary"
          onClick={handleSave}
        >
          Apply
        </Button>
        <Button
          data-id="cancel-button"
          sx={actionSx}
          variant="outlined"
          color="info"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </DialogActions>
    </>
  );
}

function inRange(value: Dayjs, start: Dayjs, end: Dayjs) {
  return value.isAfter(start) && value.isBefore(end);
}

interface Props extends Prompt {
  period?: [number, number];
  onSelect: (period: [number, number]) => void;
}

export function DatesPickerDialog({open, period, onSelect, onClose}: Props) {
  return (
    <Dialog open={open} maxWidth="md" onClose={onClose}>
      <DatesPicker
        defaultStart={period?.[0]}
        defaultEnd={period?.[1]}
        onSelect={onSelect}
        onCancel={onClose}
      />
    </Dialog>
  );
}
