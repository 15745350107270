import {Api} from 'app/api/Api';
import {ApiResponseNumeralStatus} from 'app/data/Status';
// import {queryStringSorted} from 'app/api/util/queryStringSorted';
import {Dispatcher} from 'app/data/Dispatcher';

/*
 * Workaround for tests to work because their code built not in webpack
 * and uses env variables directly
 */
function getBaseUrl() {
  if (typeof CIRRUS_API_BASE_URL !== 'undefined') {
    return CIRRUS_API_BASE_URL;
  }

  return process.env.CIRRUS_API_BASE_URL!;
}

const cirrusApi = new Api({
  baseUrl: getBaseUrl(),
});

cirrusApi.addCachedService('front', '1t', {
  billing: {
    plan: {},
    plans: {},
    v2: {
      econnect: {
        plans: {
          __noteam__: true,
        },
      },
      unify: {
        prices: {
          __noteam__: true,
        },
      },
      files: {
        __url__: 'file-management',
        prices: {
          __noteam__: true,
        },
      },
    },
  },
  devices: {
    alerts: {
      dictionary: {},
    },
    devicenumbers: {},
    task: {
      live: {
        start: {},
      },
    },
    batch_task: {},
    commands: {},
    preview: {},
    hls: {
      playlist: {},
    },
    snapshot: {
      __url__: 'state.jpg',
    },
    source: {
      __url__: '/',
      preview: {},
      snapshot: {
        __url__: 'state.jpg',
      },
    },
    sources: {},
    state: {},
    storage: {},
    afu: {},
    network: {},
    stream: {},
    unpair: {},
    streams: {
      start: {},
      stop: {},
    },
    transcribe: {
      configuration: {},
      id: {},
      start: {},
      stop: {},
      pause: {},
      resume: {},
    },
    request: {
      channel_previews: {},
      source_previews: {},
      audio_levels: {},
    },
    presets: {
      cloud: {},
      local: {
        push: {},
        remove: {
          __url__: 'delete',
        },
      },
    },
    rename: {},
    settings: {},
    remote_login: {},
    vpearls: {},
    vpearl: {
      resume: {},
      stop: {},
    },
    update: {
      status: {},
    },
    system: {
      status: {},
    },
    ssrt: {
      inputs: {},
    },
    firmware: {
      update: {
        status: {},
      },
    },
    cms: {},
    pairing_stick: {},
  },
  presets: {},
  groups: {
    device: {
      __url__: '/',
    },
    devices: {
      move: {},
    },
    batch: {
      remove: {
        __url__: 'delete',
      },
      ungroup: {
        __url__: 'remove',
      },
    },
  },
  streams: {
    attach: {},
    test: {},
    start: {},
    stop: {},
    destinations: {},
  },
  transcribing: {
    configuration: {},
    result: {},
    sessions: {
      clear: {},
      srt: {},
      txt: {},
    },
  },
  oauth: {
    logout: {
      __noteam__: true,
    },
    google: {
      __noteam__: true,
    },
    internal_cognito: {
      __noteam__: true,
    },
  },
  users: {
    me: {
      device_alerts: {},
      team: {},
      notifications: {
        accept: {},
        decline: {},
      },
      alerts: {},
      all_device_alerts: {},
      unread_device_alerts: {},
      acknowledge_device_alerts: {},
    },
    agreement: {},
    policy: {},
    subscriptions: {},
  },

  teams: {
    users: {
      master: {},
    },
    billing: {
      plan: {},
      invoice: {},
      payment_info: {},
      coupon: {
        validate: {},
        activate: {},
      },
      switch_to_ibb: {},
      cancel_switch_to_ibb: {},
      v2: {
        econnect: {
          plan: {
            resume: {},
          },
          invoice: {
            history: {},
          },
          coupon: {},
        },
        edge: {
          invoice: {
            history: {},
          },
        },
        invoice: {
          pdf: {},
        },
        unify: {
          plan: {},
          subscribe: {},
          unsubscribe: {},
        },
        files: {
          __url__: 'file-management',
          plan: {},
          invoice: {
            history: {},
          },
        },
      },
    },
    tokens: {
      new: {},
    },
  },
  versions: {
    __noteam__: true,
  },
  connections: {
    connect: {
      box: {
        storage: {},
      },
      dropbox: {
        storage: {},
      },
      google: {
        storage: {},
        stream: {},
      },
      facebook: {
        stream: {},
      },
      twitch: {
        stream: {},
      },
    },
  },

  storage: {
    test: {},
  },

  team: {
    devices: {
      __noteam__: true,
      devicenumbers: {
        __noteam__: true,
      },
      vpearl: {
        __noteam__: true,
      },
    },
    groups: {
      __noteam__: true,
      device: {
        __url__: '/',
        __noteam__: true,
      },
    },
  },

  econnect: {
    pair: {
      ms: {
        tenant: {},
      },
      zoom: {
        user: {},
      },
    },

    shareable_link: {},

    meetings: {
      connect: {},
      disconnect: {},
      participants: {
        stream: {
          settings: {
            mute: {},
          },
          status: {},
          start: {},
          stop: {},
        },
      },
      injection: {
        stream: {
          settings: {
            mute: {},
          },
          status: {},
          start: {},
          stop: {},
        },
      },
      password: {},
      account: {
        __url__: 'set-account',
      },
      permissions: {},
    },

    calls: {
      __url__: 'hosted-calls',
      teams: {
        __url__: 'ms-teams',
      },
    },

    ms_tenants: {
      __url__: 'ms-tenants',
      health: {},
    },
    zoomAccounts: {
      __url__: 'zoom-accounts',
      share: {},
    },
  },

  ws: {
    __protocol__: 'wss',
  },

  /* cms integration part */
  cms: {
    v2: {
      schedule: {
        refresh: {},

        events: {
          action: {
            start: {},
            stop: {},
            pause: {},
            resume: {},
            extend: {},
            confirm: {},
          },
          confirm: {
            devices: {},
          },
        },

        devices: {
          events: {},
          refresh: {},
          current_event: {},
        },

        current_events: {},
      },
    },
    devices: {
      events: {
        control: {
          start: {},
          stop: {},
          pause: {},
          resume: {},
          extend: {},
        },
      },
      schedule: {
        refresh: {},
      },
    },
    schedule: {
      refresh: {},
      current: {},
    },
    eventrules: {},
  },

  filemanager: {
    v1: {
      files: {
        remove: {
          __url__: 'delete',
        },
        download: {},
      },
      devices: {
        files: {},
      },
      events: {
        files: {},
      },
    },
  },
  /* eslint-enable camelcase */
});

cirrusApi.addCachedService('maintenance', '1', {
  windows: {
    __noteam__: true,
  },
});

const {front} = cirrusApi as any;
const {maintenance} = cirrusApi as any;

cirrusApi.onAjaxError((error) => {
  switch (error.status) {
    case ApiResponseNumeralStatus.Unauthorized: {
      const params = new URLSearchParams();
      params.append('next', window.location.pathname + window.location.hash);

      const url = new URL(window.location.origin);
      url.pathname = '/login.html';
      url.hash = `/login?${params.toString()}`;
      window.location.assign(url);
      break;
    }

    case ApiResponseNumeralStatus.MethodNotAllowed:
      // TODO: WCX-1176: assuming application is in the root
      if (error?.data?.ErrorData?.NewTeam) {
        window.location.href = `${window.location.origin}/${error.data.ErrorData.NewTeam}`;
      } else {
        Dispatcher.emit('logout');
        front
          .oauth()
          .logout()
          .get()
          .finally(() => {
            window.location.assign('/login.html');
          });
      }

      break;
    default:
      console.log('Unhandled err', error);
      break;
  }
});

export {cirrusApi, front, maintenance};
