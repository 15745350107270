import React, {forwardRef, useMemo, useState} from 'react';
import {Button, Card, IconButton, ThemeProvider} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CancelIcon from '@mui/icons-material/Cancel';
import {StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {
  Files,
  FilesApi,
  DeleteRecordingsDialog,
  DownloadRecordingsDialog,
  RevokeSharingDialog,
} from 'app/components/entities/files';
import {darkTheme} from 'app/themes/app';
import {Notifications} from 'app/components/Notifications';
import {TotalAndSize} from '../TotalAndSize/TotalAndSize';

const rootStyles: StyleSx = {
  p: 2,
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  height: 64,
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  gap: 2,
};

interface Props extends Sx {
  teamId: string;
  recordings: Files.Recording[];
  onClose: () => void;
}

export const BatchRecordings = forwardRef<HTMLDivElement, Props>(
  ({sx, teamId, recordings, onClose}, ref) => {
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [downloadDialog, setDownloadDialog] = useState(false);
    const [revokeDialog, setRevokeDialog] = useState(false);

    const {shared, uploaded} = useMemo(
      () => ({
        shared: recordings.filter((r) => r.share),
        uploaded: recordings.filter((r) => r.uploaded),
      }),
      [recordings],
    );

    const handleDelete = async () => {
      try {
        await FilesApi.batchDelete(recordings.map((r) => r.id));
        setDeleteDialog(false);
      } catch {
        Notifications.addErrorNotification('Failed to delete recording(s)');
      }
    };

    const handleRevoke = async () => {
      try {
        await FilesApi.batchRevoke(
          teamId,
          shared.map((r) => r.id),
        );
        setRevokeDialog(false);
      } catch {
        Notifications.addErrorNotification('Failed to delete recording(s)');
      }
    };

    const handleDownload = async () => {
      // need backend implementation
    };

    return (
      <>
        <DeleteRecordingsDialog
          open={deleteDialog}
          onDelete={handleDelete}
          total={recordings.length}
          onClose={() => setDeleteDialog(false)}
        />

        <DownloadRecordingsDialog
          open={downloadDialog}
          recordings={uploaded.map((r) => r.name)}
          batching={true}
          onDownload={handleDownload}
          onClose={() => setDownloadDialog(false)}
        />

        <RevokeSharingDialog
          open={revokeDialog}
          total={shared.length}
          onRevoke={handleRevoke}
          onClose={() => setRevokeDialog(false)}
        />

        <ThemeProvider theme={darkTheme}>
          <Card
            data-id="recordings-batch"
            ref={ref}
            sx={packSx(rootStyles, sx)}
            variant="elevation"
            elevation={8}
          >
            <TotalAndSize sx={{mr: 'auto'}} dataId="batch-summary" recordings={recordings} />

            {shared.length > 0 && (
              <Button
                data-id="revoke-sharing-btn"
                variant="outlined"
                color="info"
                startIcon={<CancelIcon />}
                onClick={() => setRevokeDialog(true)}
              >
                Stop sharing
              </Button>
            )}

            <Button
              data-id="delete-btn"
              variant="outlined"
              color="info"
              startIcon={<DeleteIcon />}
              onClick={() => setDeleteDialog(true)}
            >
              Delete
            </Button>

            <Button
              data-id="download-btn"
              sx={{display: 'none'}}
              variant="contained"
              color="primary"
              startIcon={<CloudDownloadIcon />}
              onClick={() => setDownloadDialog(true)}
            >
              Download
            </Button>

            <IconButton data-id="close-batch-btn" onClick={onClose}>
              <ClearIcon />
            </IconButton>
          </Card>
        </ThemeProvider>
      </>
    );
  },
);
