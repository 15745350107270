import React, {useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {Prompt} from 'app/types/common';

interface Props extends Prompt {
  total: number;
  onRevoke: () => Promise<void>;
}

export function RevokeSharingDialog({open, total, onClose, onRevoke}: Props) {
  const [loading, setLoading] = useState(false);

  const handleRevoke = async () => {
    try {
      setLoading(true);
      await onRevoke();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" onClose={onClose}>
      <DialogTitle>
        Are you sure you want to stop sharing{' '}
        {total > 1 ? (
          <>
            these{' '}
            <Typography component="span" variant="inherit" color="primary.main">
              {total}
            </Typography>{' '}
            files
          </>
        ) : (
          'this file'
        )}
        ?
      </DialogTitle>

      <DialogContent>
        <Typography textAlign="center" color="text.secondary">
          This action cannot be undone. Anyone with the link to this video will lose access after
          this action.
        </Typography>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          data-id="confirm-revoke-btn"
          loading={loading}
          color="error"
          variant="contained"
          onClick={handleRevoke}
        >
          Stop sharing
        </LoadingButton>

        <Button data-id="cancel-revoke-btn" variant="outlined" color="info" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
