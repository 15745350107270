import {front} from 'app/api/CirrusApi';
import {FilesContracts, Files} from 'app/components/entities/files/model';
import {apiClient} from 'app/components/shared';

export class FilesApi {
  static async getTeamRecordings(): Promise<{files: FilesContracts.Recording[]}> {
    return front.filemanager().v1().files().get();
  }

  static async getUnitRecordings(deviceId: string): Promise<{files: FilesContracts.Recording[]}> {
    return front.filemanager().v1().devices(deviceId).files().get();
  }

  static async batchDelete(ids: string[]): Promise<void> {
    return front.filemanager().v1().files().remove().post(ids);
  }

  static async deleteRecording(id: string): Promise<void> {
    return front.filemanager().v1().files(id).delete();
  }

  static getDownloadUrl(teamId: string, fileId: string): string {
    return `/front/api/v1t/team/${teamId}/filemanager/v1/files/${fileId}/download`;
  }

  static async shareFile(
    teamId: string,
    fileId: string,
    authorId: string,
    authorName: string,
  ): Promise<void> {
    return apiClient.post(`/front/api/v1t/team/${teamId}/filemanager/v1/files/${fileId}/share`, {
      shared_by_user_id: authorId,
      shared_by_user_name: authorName,
    });
  }

  static async revokeShare(teamId: string, fileId: string): Promise<void> {
    return apiClient.delete(`/front/api/v1t/team/${teamId}/filemanager/v1/files/${fileId}/share`);
  }

  static async batchRevoke(teamId: string, ids: string[]): Promise<void> {
    await apiClient.post(`/front/api/v1t/team/${teamId}/filemanager/v1/files/shares/delete`, ids);
  }

  static async getPublicFile(fileId: string): Promise<Files.PublicInfo> {
    return apiClient.get<Files.PublicInfo>(`front/api/v2/public/files/${fileId}/info`);
  }

  static getPublicLink(fileId: string): string {
    return `front/api/v2/public/files/${fileId}`;
  }
}
