import {useQuery} from '@tanstack/react-query';
import {Team} from 'app/components/entities/team';
import {User} from 'app/components/entities/user';

export function useCloud() {
  const teamQuery = useQuery<Team.TeamDetails>(['team']);
  const userQuery = useQuery<User.Profile>(['user']);

  if (!teamQuery.data || !userQuery.data) {
    throw new Error('No team or user');
  }

  return {team: teamQuery.data, user: userQuery.data};
}
