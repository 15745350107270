import React, {useRef, useState} from 'react';
import {
  Box,
  ButtonBase,
  Popover,
  PopoverOrigin,
  Stack,
  Tooltip,
  Typography,
  buttonBaseClasses,
} from '@mui/material';
import {grey} from '@mui/material/colors';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import GroupIcon from '@mui/icons-material/Group';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {StyleSx, Sx} from 'app/types/common';
import {palette} from 'app/themes/app';
import {TeamList} from 'app/components/Navigation/TeamSelector/TeamList';
import {packSx} from 'app/util/packSx/packSx';

const bgcolor = palette.dark1;
const rootSx: StyleSx = {
  width: '100%',
  px: 2,
  py: 1,
  [`&:hover, &.${buttonBaseClasses.focusVisible}`]: {bgcolor},
};
const listSx: StyleSx = {maxHeight: '50vh', width: 230};

type OriginType = 'anchor' | 'transform';

const normalOrigin: Record<OriginType, PopoverOrigin> = {
  anchor: {vertical: 'bottom', horizontal: 'left'},
  transform: {vertical: 'top', horizontal: 'left'},
};

const compactOrigin: Record<OriginType, PopoverOrigin> = {
  anchor: {vertical: 'top', horizontal: 'right'},
  transform: {vertical: 'top', horizontal: 'left'},
};

interface Props extends Sx, React.ComponentProps<typeof TeamList> {
  email: string;
  teamId: string;
  teamName: string;
  compact: boolean;
}

export function TeamSelector({
  compact,
  email,
  teamId,
  teamName,
  teams,
  sx,
  onCreate,
  onLogout,
  onMyTeam,
}: Props) {
  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const popperOrigin = compact ? compactOrigin : normalOrigin;

  const handleMyTeam = () => {
    onMyTeam();
    setOpen(false);
  };

  return (
    <Box sx={sx}>
      <ButtonBase
        data-id="team-selector"
        ref={anchorRef}
        sx={packSx(rootSx, {bgcolor: open ? bgcolor : 'transparent'})}
        onClick={() => setOpen(true)}
      >
        {compact ? (
          <Tooltip
            placement="right"
            title={
              <React.Fragment>
                <Typography data-id="user-email" mb={0.5} color={grey[300]}>
                  {email}
                </Typography>
                <Typography data-id="team-name" color={palette.white} fontWeight={600}>
                  {teamName}
                </Typography>
              </React.Fragment>
            }
          >
            <GroupIcon sx={{color: palette.darkerGray, fontSize: 22}} />
          </Tooltip>
        ) : (
          <Stack
            direction="row"
            minWidth={0}
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Stack alignItems="flex-start" gap={0.5} minWidth={0}>
              <Typography fontSize={12} data-id="user-email" color={grey[400]} maxWidth="100%">
                {email}
              </Typography>

              <Typography
                data-id="team-name"
                component="span"
                maxWidth="100%"
                color={palette.white}
                fontWeight={600}
              >
                <OverflowTooltip>{teamName}</OverflowTooltip>
              </Typography>
            </Stack>
            <UnfoldMoreIcon sx={{color: grey[500]}} />
          </Stack>
        )}
      </ButtonBase>

      <Popover
        open={open}
        anchorEl={anchorRef.current}
        anchorOrigin={popperOrigin.anchor}
        transformOrigin={popperOrigin.transform}
        onClose={() => setOpen(false)}
      >
        <TeamList
          sx={listSx}
          teamId={teamId}
          teams={teams}
          onCreate={onCreate}
          onLogout={onLogout}
          onMyTeam={handleMyTeam}
        />
      </Popover>
    </Box>
  );
}
