import React, {useCallback, useState} from 'react';
import {QueryClientProvider} from '@tanstack/react-query';
import {Login} from 'app/components/Login';
import {router, ROUTE_NAME} from 'app/router/login';
import {AnalyticsTracker} from 'app/util/AnalyticsTracker';
import {getRouteNameByAuthState} from 'app/components/Login/utils';
import {isNil} from 'app/util/isNil';
import {useComponentDidMount} from 'app/hooks/useComponentDidMount';
import {queryClient} from 'app/components/Root/app';
import {MainTheme} from 'app/themes/MainTheme';

export function LoginRoot() {
  const [initialized, setInitialized] = useState(false);
  const [routeName, setRouteName] = useState('');
  const [routeQuery, setRouteQuery] = useState<Record<string, any>>({});

  useComponentDidMount(() => {
    router.init(({name, query = {}}) => {
      setRouteName(name);
      setRouteQuery(query);
    });

    // eslint-disable-next-line no-new
    new AnalyticsTracker({router});

    // Save Team Id before login redirects
    // const currentTeamTracker = new CurrentTeamTracker();
    // currentTeamTracker.save();

    setInitialized(true);
  });

  const handleChangeAuthState = useCallback((authState) => {
    const routeName = getRouteNameByAuthState(authState);
    router.go(routeName, router.state.params, router.state.query);
  }, []);

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <MainTheme>
          {initialized && (
            <Login
              invited={routeQuery.invited === 'yes'}
              error={isNil(routeQuery.error) === false}
              next={routeQuery.next}
              state={routeQuery.state}
              routeName={routeName}
              onClickLogo={goHomePage}
              onChangeAuthState={handleChangeAuthState}
            />
          )}
        </MainTheme>
      </QueryClientProvider>
    </div>
  );
}

function goHomePage() {
  router.go(ROUTE_NAME.SIGN_IN);
}
