import React from 'react';
import {DataId, Sx} from 'app/types/common';
import {UnitStatus} from 'app/components/DeviceDetails/DeviceStatusText/UnitStatus/UnitStatus';

type Variant = React.ComponentProps<typeof UnitStatus>['variant'];
type Mode = 'starting' | 'resuming';

interface Props extends Sx, DataId {
  progress: number;
  mode: Mode;
}

function useStatus(progress: number, mode: Mode): [value: string, variant: Variant] {
  if (progress >= 80) {
    return ['Taking longer than expected...', 'info'];
  }

  const value = mode === 'resuming' ? 'Resuming' : progress < 55 ? 'Initializing' : 'Starting';
  return [`${value}...`, 'processing'];
}

export function UnifyConnectionStatus({sx, dataId, progress, mode}: Props) {
  const [value, variant] = useStatus(progress, mode);

  return <UnitStatus data-id={dataId} sx={sx} value={value} variant={variant} />;
}
