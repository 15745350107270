import {front} from 'app/api/CirrusApi';
import {ApiResponseStatus} from 'app/data/Status';
import {Dispatcher} from 'app/data/Dispatcher';
import {ActionTypes} from 'app/data/ActionTypes';
import {getApiErrorMessage} from 'app/util/getApiErrorMessage';
import {Notifications} from 'app/components/Notifications';
import {TeamDetailsDto} from 'app/models/Team/dto';
import {AjaxComplexSuccessfulResponseType} from 'app/api/types';
import {CloudContract} from 'app/contracts/cloud';

export class TeamApiService {
  async fetchTeamDetails(teamId: string): Promise<TeamDetailsDto> {
    return front.teams(teamId).get();
  }

  async rename(teamId: string, name: string) {
    return front
      .teams(teamId)
      .put({
        Name: name,
      })
      .then((resp: AjaxComplexSuccessfulResponseType) => {
        if (resp.Status === ApiResponseStatus.Ok) {
          Dispatcher.emit(ActionTypes.TEAMS_TEAM_RENAMED, {
            id: teamId,
            name,
          });
        }
      })
      .catch((error) => {
        const errorText = getApiErrorMessage(error);

        Notifications.addErrorNotification(
          `Team update error${errorText ? `: "${errorText}"` : ''}`,
        );

        throw error;
      });
  }

  async createTeam(name: string): Promise<{teamId: string}> {
    return front
      .teams()
      .post({Name: name, Billing: {}})
      .then((response: AjaxComplexSuccessfulResponseType<{ID: string}>) => {
        return {
          teamId: response.ID,
        };
      });
  }

  static async deleteTeam(teamId: string): Promise<void> {
    return front.teams(teamId).delete();
  }

  static async logout(): Promise<void> {
    return front.oauth().logout().get();
  }

  static async getTeams(): Promise<CloudContract.Team[]> {
    return front.teams().get();
  }

  static async createTeam(name: string): Promise<{ID: string}> {
    return front.teams().post({Name: name, Billing: {}});
  }
}

export const teamApiService = new TeamApiService();
