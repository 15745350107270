/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import {Box, Button, Card, Divider, Link, Stack, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {DataId, StyleSx, Sx} from 'app/types/common';
import {useMounted} from 'app/hooks/useIsMounted';
import {packSx} from 'app/util/packSx/packSx';
import {
  FilesBilling,
  SubscribeDialog,
  UnsubscribeDialog,
} from 'app/components/entities/billing/files';

const rootSx: StyleSx = {
  display: 'flex',
  flexDirection: 'column',
  px: 4,
  py: 3,
  height: 290,
  gap: 1,
  bgcolor: grey[50],
};

const inactiveSx: StyleSx = {
  borderStyle: 'dashed',
  bgcolor: ({palette}) => palette.background.paper,
};

interface Props extends Sx, DataId {
  hasPayment: boolean;
  price: FilesBilling.Price;
  active: boolean;
  onSubscribe: () => Promise<void>;
  onUnsubscribe: () => Promise<void>;
  onEditPayment: () => void;
}

export function FilesCard({
  dataId,
  sx,
  price,
  active,
  hasPayment,
  onSubscribe,
  onUnsubscribe,
  onEditPayment,
}: Props) {
  const mounted = useMounted();

  const [subscribeDialog, setSubscribeDialog] = useState(false);
  const [unsubscribeDialog, setUnsubscribeDialog] = useState(false);

  const handleChange = () => {
    if (active) {
      setUnsubscribeDialog(true);
    } else {
      setSubscribeDialog(true);
    }
  };

  const handleSubscribe = async () => {
    await onSubscribe();

    if (mounted()) {
      setSubscribeDialog(false);
    }
  };
  const handleUnsubscribe = async () => {
    await onUnsubscribe();

    if (mounted()) {
      setUnsubscribeDialog(false);
    }
  };

  return (
    <>
      <Card data-id={dataId} sx={packSx(rootSx, !active && inactiveSx, sx)} variant="outlined">
        <Box flex={1}>
          <Stack mb={3} direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" color={!active ? 'text.disabled' : 'inherit'} fontWeight={600}>
              File management
            </Typography>

            <Button
              data-id="switch-subscription-btn"
              variant="outlined"
              color={active ? 'error' : 'primary'}
              disabled={!hasPayment}
              onClick={handleChange}
            >
              {active ? 'Unsubscribe' : 'Subscribe'}
            </Button>
          </Stack>

          {active && (
            <Typography variant="h5" color="primary.main" fontWeight={600} mb={0.5}>
              Plan active
            </Typography>
          )}

          <Typography>
            First {price.free.storage} GB are free for any subscribed account.
          </Typography>

          {!active && (
            <Link
              data-id="file-management-link"
              href="https://www.epiphan.com/file-management"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </Link>
          )}
        </Box>

        <Divider />

        <Stack height={58} justifyContent="center">
          {!hasPayment && (
            <Stack direction="row" alignItems="center" gap={1}>
              <CreditCardIcon color="primary" />
              <Link
                sx={{verticalAlign: 'baseline'}}
                component="button"
                variant="inherit"
                onClick={onEditPayment}
              >
                Add billing information to subscribe
              </Link>
            </Stack>
          )}
        </Stack>
      </Card>

      <SubscribeDialog
        open={subscribeDialog}
        price={price}
        onClose={() => setSubscribeDialog(false)}
        onConfirm={handleSubscribe}
      />

      <UnsubscribeDialog
        open={unsubscribeDialog}
        onClose={() => setUnsubscribeDialog(false)}
        onConfirm={handleUnsubscribe}
      />
    </>
  );
}
