/* eslint-disable max-classes-per-file */
import {isNil} from 'app/util/isNil';
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';

export type ErrorResponse = {
  Status: string;
  StatusCode: number;
  Error: string;
  ErrorData?: any;
};

export class AbortError extends Error {}

export class ApiError extends Error {
  data: ErrorResponse;

  constructor(data: ErrorResponse) {
    super();
    this.data = data;
  }
}

export class UnknownError extends Error {}

function errorHandler(e: unknown): never {
  if (axios.isCancel(e)) {
    throw new AbortError();
  }

  if (axios.isAxiosError(e)) {
    const payload = e.response?.data;

    if (isErrorResponse(payload)) {
      throw new ApiError(payload);
    }
  }

  throw new UnknownError();
}

export function isErrorResponse(e: unknown): e is ErrorResponse {
  const casted = e as ErrorResponse;
  return !isNil(casted.Status) && !isNil(casted.StatusCode) && !isNil(casted.Error);
}

export function isApiError(e: unknown): e is ApiError {
  return e instanceof ApiError;
}

export function isAjaxError(e: unknown): e is AxiosError {
  return axios.isAxiosError(e);
}

class ApiClient {
  instance = axios.create({});

  private async request<Res, Data = any>(config: AxiosRequestConfig<Data>): Promise<Res> {
    try {
      const res = await this.instance.request<Res, AxiosResponse<Res>, Data>(config);
      return res.data;
    } catch (error) {
      console.log('req error', error);
      errorHandler(error);
    }
  }

  async get<Res>(url: string, config?: AxiosRequestConfig): Promise<Res> {
    return this.request<Res>({...config, url, method: 'GET'});
  }

  async post<Res, Data = any>(url: string, data?: Data, config?: AxiosRequestConfig): Promise<Res> {
    return this.request<Res, Data>({...config, url, data, method: 'POST'});
  }

  async put<Res, Data = any>(url: string, data?: Data, config?: AxiosRequestConfig): Promise<Res> {
    return this.request<Res, Data>({...config, url, data, method: 'PUT'});
  }

  async delete<Res>(url: string, config?: AxiosRequestConfig): Promise<Res> {
    return this.request<Res>({...config, url, method: 'DELETE'});
  }
}

export const apiClient = new ApiClient();
