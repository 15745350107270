import React, {useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import {useMounted} from 'app/hooks/useIsMounted';
import {Prompt} from 'app/types/common';

type Props = {
  loading: boolean;
  total: number;
  onDelete: () => Promise<void>;
  onCancel: () => void;
};

function DeleteRecordings({loading, total, onDelete, onCancel}: Props) {
  const title =
    total > 1 ? (
      <>
        these{' '}
        <Typography component="span" variant="inherit" color="primary.main">
          {total}
        </Typography>{' '}
        files
      </>
    ) : (
      'this file'
    );

  return (
    <Box>
      <DialogTitle>Are you sure you want to delete {title}?</DialogTitle>

      <DialogContent>
        <Typography textAlign="center" color="text.secondary">
          This action cannot be undone. Anyone with the link to this video will lose access after
          this action.
        </Typography>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          data-id="confirm-delete-btn"
          loading={loading}
          color="error"
          variant="contained"
          onClick={onDelete}
        >
          Yes, delete
        </LoadingButton>

        <Button data-id="cancel-delete-btn" variant="outlined" color="info" onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Box>
  );
}

type DialogProps = Prompt & Omit<Props, 'onCancel' | 'loading'>;

export function DeleteRecordingsDialog({open, total, onDelete, onClose}: DialogProps) {
  const mounted = useMounted();
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await onDelete();
    } finally {
      if (mounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" onClose={onClose}>
      <DeleteRecordings
        loading={loading}
        total={total}
        onDelete={handleDelete}
        onCancel={onClose}
      />
    </Dialog>
  );
}
