import React, {useEffect, useState} from 'react';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {DataId, StyleSx, Sx} from 'app/types/common';
import {useActiveItems} from 'app/components/FleetManager/DeviceCard/StatusList/DeviceStatusIndicator/useActiveItems';
import {DeviceStatusText} from 'app/components/DeviceDetails/DeviceStatusText/DeviceStatusText';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {Box, Stack, Typography} from '@mui/material';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';

interface StatusItem {
  id: string;
  icon: React.ReactNode;
  label: string;
}

interface ListProps {
  items: StatusItem[];
}

export function IndicatorList({items}: ListProps) {
  const [tooltipText, setTooltipText] = useState('');

  useEffect(() => {
    let text = '';

    if (items.length === 1) {
      text = items[0]?.label ?? '';
    } else {
      items.forEach((item, index, arr) => {
        if (index === 0) {
          text += item.label;
        } else if (index === arr.length - 1) {
          text += ` and\n${item.label}`;
        } else {
          text += `, ${item.label}`;
        }
      });
    }

    setTooltipText(text);
  }, [items]);

  return (
    <Tooltip content={tooltipText} delay={250}>
      <Stack direction="row" alignItems="center" gap={0.5}>
        {items.map((item) => (
          <span key={item.id} className="device-status-indicator__status-list-item">
            {item.icon}
          </span>
        ))}
      </Stack>
    </Tooltip>
  );
}

const statusSx: StyleSx = {fontSize: 12, fontWeight: 600};

interface Props extends Sx, DataId {
  device: AnyDeviceModelType;
  progress?: number;
}

export function DeviceStatusIndicator({sx, device, progress = 0}: Props) {
  const streaming = device.isStreaming();
  const recording = device.isRecording();
  const transcribing = device.isTranscribing();

  const showIndicators = streaming || recording || transcribing;

  const activeItems = useActiveItems({streaming, recording, transcribing});

  const model = device.getModelName();
  const status = device.getStatus();

  const isUnify = ModelService.isUnify(model);

  const resuming = status === 'resuming';
  const starting = status === 'starting';

  const showEstimate = isUnify && (starting || resuming) && progress < 80;

  return (
    <Stack sx={sx}>
      {showIndicators ? (
        <IndicatorList items={activeItems} />
      ) : (
        <Box>
          <DeviceStatusText
            sx={statusSx}
            model={model}
            status={status}
            progress={progress}
            stateTime={device.getDeviceStateTime()}
          />
          {showEstimate && (
            <Typography fontSize={12}>Est time: {resuming || progress < 55 ? 5 : 1} min</Typography>
          )}
        </Box>
      )}
    </Stack>
  );
}
