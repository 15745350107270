import React from 'react';
import dayjs from 'dayjs';
import {filesize} from 'filesize';
import {Box, Checkbox, Grid, Stack} from '@mui/material';
import {grey} from '@mui/material/colors';
import {DataId, StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {Files} from 'app/components/entities/files';
import {RecordingName} from 'app/components/features/files/ui/RecordingName/RecordingName';
import {RecordingDevice} from 'app/components/features/files/ui/RecordingDevice';
import {formatPlayback} from 'app/util/time';
import {ShareFile, FileMenu} from 'app/components/features/files';

const rootSx: StyleSx = {
  py: 0.5,
  display: 'flex',
  borderBottom: 1,
  minHeight: 48,
  borderColor: (t) => t.palette.divider,
  '&:hover': {bgcolor: grey[50]},
};

const selectedSx: StyleSx = {bgcolor: grey[100]};
const uploadingSx: StyleSx = {opacity: 0.7};

type Props = Sx &
  DataId & {
    selected: boolean;
    recording: Files.Recording;
    device?: AnyDeviceModelType;
    showUnit?: boolean;
    loadingDevice?: boolean;
    onSelect: () => void;
    onDelete: () => Promise<void>;
    onDownload: () => Promise<void>;
    onShare: () => Promise<void>;
    onRevokeSharing: () => Promise<void>;
  };

export function RecordingCard({
  dataId,
  sx,
  selected,
  recording,
  showUnit,
  device,
  loadingDevice,
  onSelect,
  onDelete,
  onDownload,
  onShare,
  onRevokeSharing,
}: Props) {
  const {uploaded} = recording;

  return (
    <Box data-id={dataId} sx={packSx(rootSx, selected && selectedSx, !uploaded && uploadingSx, sx)}>
      <Grid container={true} columns={24} columnGap={1} alignItems="center">
        <Grid width={40} item={true}>
          <Checkbox
            data-id="recording-checkbox"
            checked={selected}
            disabled={!uploaded}
            onChange={() => onSelect()}
          />
        </Grid>

        <Grid xs={true} item={true} minWidth={0}>
          <RecordingName
            sx={{maxWidth: '100%'}}
            name={recording.name}
            editable={false}
            onSave={() => {}}
          />
        </Grid>

        <Grid width={100} item={true} data-id="recording-size">
          {filesize(recording.size)}
        </Grid>

        <Grid width={100} item={true} data-id="recording-time">
          {formatPlayback(recording.duration)}
        </Grid>

        <Grid width={100} item={true} data-id="recording-date">
          {formateDate(recording.startAt)}
        </Grid>

        {showUnit && (
          <Grid width={100} item={true}>
            <RecordingDevice
              dataId="recording-device-link"
              deviceId={device?.getId()}
              name={device?.getName()}
              loading={loadingDevice}
            />
          </Grid>
        )}

        <Grid width={140} item={true}>
          <Stack direction="row" justifyContent="space-between">
            <ShareFile
              filename={recording.name}
              share={recording.share}
              link={recording.publicUrl}
              onShare={onShare}
            />

            <FileMenu
              dataId="recording-actions"
              file={recording}
              onDownload={onDownload}
              onDelete={onDelete}
              onRevokeSharing={onRevokeSharing}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

function formateDate(datetime: number): string {
  return dayjs.unix(datetime).format('MMM D, YYYY');
}
